
/*@media*/
@media screen and (max-height:  860px) {
} 
@media screen and (max-height:  680px) {
    .menu{
        top: 10px;
    }
    .menu .cw_main{
        padding: 0 10px;
    }
}
@media screen and (max-height:  600px) {
     logo{
        max-width: 130px;
    }
    logo img{
        width: 100%;
    }
    .menu_btn{
        width: 50px;
        height: 50px;
    }
    .menu_btn{
        font-size: 14px;
    }
    .call_phone{
        font-size: 10px;
        margin-right: 12px;
        line-height: 28px;
    }
    .call_btn {
        width: 110px;
        height: 28px;
        font-size: 12px;
    }
    .dom_rf_hd{
        width: 200px;
        right: 10px;
    }
    h1{
        font-size:  38px;
    }
    .hd_loc {
        font-size: 24px;
        line-height: 16px;
        padding-left: 23px;
        background-size: 20px 18px;
        padding-bottom: 0;
        padding-top: 5px;
        margin-top: 16px;
    }
    .hd_info {
        width: 172px;
        margin-top: 70px;
        padding-top: 14px;
        font-size: 12px;
    }
    .hd_info b{
        font-size: 18px;
    }
    h1 .dec1 {
        width: 51px;
        height: 47px;
        bottom: 12px;
        left: -34px;
    }
    h1 .dec2 {
        width: 82px;
        height: 70px;
        bottom: -11px;
        right: -30px;
    }
}
@media screen and (max-width: 1850px){
}
@media screen and (max-width: 1750px){
    .advantage .cw_main {
        min-height: 647px;
    }
    .parking_img {
        display: block;
        position: absolute;
        left: 10px;
        bottom: -72px;
        width: 50%;
    }
    .ra_img{
        width: 50%;
        right: 10px;
    }
    .ra_list span+span{
        margin-top: 15px;
    }
    .ra_list span{
        font-size: 20px;
        min-height: 26px;
        background-size: 25px;
        padding-left: 34px;
    }
    .ra_wrap p{
        max-width: 670px;
        font-size: 22px;
    }
    .ra_list{
        max-width: 670px;
        /* min-height: 306px; */
    }
    .ra_btn {
        height: 86px;
        bottom: -43px;
    }
    .ra_img{
        bottom: -43px;
    }
    .advant_slid_img{
        width: 50%;
        right: 10px;
    }
    .adv_l{
        padding-top: 50px;
    }
    .advant_info_in span{
        margin-top: 20px;
    }
    .ap_right{
        width: 50%;
    }
    .ap_img {
        left: 10px;
        max-width: 45%;
    }
    .presentation_img {
        right: 10px;
        top: -50px;
        max-width: 50%;
    }
    .ap_info_img{
        right: 10px;
    }
}
@media screen and (max-width: 1550px){
    .plpr_slid .slick-prev{
        left: -8px;
    }
    .plpr_slid .slick-next{
        right: -8px;
    }
    .loc_img{
        right: -150px;
        height: 100%;
    }
    .loc .cw_main{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .genplan .cw_main{
        width: 100%;
        top: 20px;
    }
    .genplan .cw_main .genp_info span{
        margin-top: 10px;
    }
    .lit_num{
        height: 56px;
    }
    .lit_info{
        height: 56px;
        width: 136px; 
    }
    .lit_info span{
        font-size: 18px;
    }
    .lit_info sup{
        font-size: 12px;
    }
    .lit_ident{
        width: 184px; 
    }
    .infra_img {
        display: block;
        position: absolute;
        right: 13px;
        width: 600px;
        top: -120px;
    }
    .infra_inn{
        max-width: 500px;
    }
    .infra_info{
        font-size: 18px;
    }
    .infra_advant{
        margin-top: 30px;
    }
    .inf_bld_txt{
        font-size: 34px;
    }
    .inf_el span{
        font-size: 14px;
    }
    
}
@media screen and (max-width: 1500px) {
    .cw_main{
        padding-left: 52px;
    }
}
@media screen and (max-width: 1440px) {
    .demo .tm span + span {
        font-size: 22px;
    }
    .about_us > .dec2 {
        display: none;
    }
    .parking_action .cw_main {
        max-width: 1070px;
        background: url(../images/parking_bg.jpg) no-repeat -140px top;
        background-size: cover;
        padding-top: 61px;
        padding-bottom: 120px;
        padding-right: 30px;
    }
    .parking_action__content {
        margin-top: 24px;
    }
    .parking_action__list > li + li {
        margin-top: 14px;
    }
    .parking_action__content .btn_main {
        margin-top: 20px;
    }
    .parking_action__title {
        font-size: 38px;
    }
    .hod_wrap {
        width: 100% !important;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 1410px) {
    .hod_wrap{
        padding: 0 10px;
    }
    .hod_str{
        padding-top: 150px;
        padding-bottom: 40px;
    }
    .parking{
        padding-top: 60px;
    }
    .hb_list{
        max-width: 720px;
        position: relative;
        z-index: 4;
    }
    .hb_el{
        width: 355px;
    }
    .hb_img {
        left: 10px;
        width: 40%;
    }
    .vacant_apart{
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .vacant_apart a+a {
        margin-left: 12px;
    }
    .va_floor_slid .va_txt{
        margin-right: 30px;
    }
    .va_floor_slid .slid_style_react.MuiSlider-root {
        width: 590px;
    }
    .va_param{
        margin-top: 30px;
    }
    .vacant_apart .form_main{
        margin-top: 40px;
    }
    .plan_info{
        margin-left: 198px;
        font-size: 20px;
    }
    .plpr_in {
        width: 90%!important;
        padding: 18px;
    }
    .plpr_img{
        height: 210px;
        margin-bottom: 16px;
    }
    .plpr_img img{
        max-width: 100%;
        max-height: 210px;
    }
    .ra_left{
        width: 46%;
    }
    .ra_el{
        font-size: 28px;
    }
    .ra_el+.ra_el {
        margin-left: 40px;
    }
    .ra_wrap p{
        font-size: 18px;
        line-height: 1;
    }
    .advant_slid_img{
        top: 190px;
    }
    .adv_info{
        font-size: 18px;
    }
    .cw_main{
        padding-left: 10px;
        padding-right: 10px;
        padding-left: 52px;
    }
    .tm{
        font-size: 46px;
    }
    .ap_info_img{
        top: -20px;
    }
    .ap_right > p{
        padding-top: 42px;
    }
    .galery .cw_main{
        width: 100%;
    }
    .gl_nav{
        height: 70px;
    }
    .gl_nav_l, .gl_nav_r{
        height: 70px;
        width: 70px;
    }
    .gl_nav_l span, .gl_nav_r span{
        height: 70px;
        width: 38px;
    }
    .galery .cw_main{
        top: 26px;
    }
    .gl_nav_wrap.cw_main{
        top: auto;
    }
    .galery > .dec3 {
        width: 171px;
        height: 135px;
        top: -90px;
        right: -78px;
        transform: rotate(180deg);
    }
    .galery > .dec2 {
        width: 124px;
        height: 94px;
        bottom: -54px;
        left: -46px;
    }
    .about_us .cw_main{
        padding-top: 50px;
    }
    .ab_list{
        margin-top: 50px;
    }
    .doc_btn{
        width: 30%;
    }
    .doc_bg{
        width: 70%;
    }
    .ra_list{
        min-height: 306px;
    }
}
@media screen and (max-width: 1360px) {
    .loc_text{
        margin-top: 40px;
    }
    .kellers_img {
        display: block;
        position: absolute;
        top: -32px;
        right: 10px;
        max-width: 45%;
    }
    .kellers{
        padding-top: 50px;
    }
    
}
@media screen and (max-width: 1280px) {
    .parking_action .cw_main {
        position: relative;
    }
    .parking_action .cw_main:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #000;
        opacity: 0.2;
    }
    .parking_action__title, .parking_action__content {
        position: relative;
        z-index: 10;
    }
    .parking_action__content .btn_main {
        color: #fff;
        border-color: #fff;
    }
    .pu_action__img {
        width: 100%;
    }

    .pu_action {
        width: 610px;
    }
}
@media screen and (max-width: 1250px){
}
@media screen and (max-width: 1150px){
    h1 .dec1 {
        left: -47px;
    }
    h1 .dec2 {
        right: -57px;
    }
    .nemu_action {
        font-size: 16px;
        line-height: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .banner_action {
        margin-top: 80px;
    }
    .yrs_list {
        text-align: center;
        min-width: 10px;
    }
        .yrs_list span {
            margin-left: 10px;
            margin-right: 10px;
        }
    .loc_img{
        display: none;
    }
    .loc_img_m{
        display: block;
    }
    .military_btn {
        width: 200px;
        height: 55px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
    }
    .cw_main{
        padding-left: 10px;
    }
    .pu_floor_icon{
        display: block !important;
        margin-top: 20px;
        margin-bottom: 0;
    }
    .select_floor{
        display: none;
    }
    .ab_list{padding: 0;}
    .advantage .cw_main{
        min-height: 1px;
    }
    .ra_list{
        min-height: 1px;
    }
    .dev_img{
        max-width: 100%;
    }
    .pu_doc.popup_main {
        max-width: 300px;
        padding: 60px 10px 50px 10px;
    }
    .doc_list{
        margin-top: 40px;
    }
    .doc_col{
        display: block;
        width: 100%;
    }

    .action_mounth .cw_main {
        max-width: none;
    }

    .action_mounth  .aml__title {
        font-size: 21px;
        line-height: 1.2;
    }
    .doc_col + .doc_col{
        padding-left: 0;
        margin-top: 16px;
    }
    .pu_keller_wrap{
        float: none;
        width: 100%;
        margin-top: 30px;
    }
    .pu_keller_wrap .pu_form{
        margin-top: 0;
    }
    .pu_keller_slid_wrap{
        width: 100%;
        margin-top: 30px;
        float: none;
    }
    .kl_in img{width: 100%;}
    .pu_keller.popup_main {
        max-width: 300px;
        padding: 60px 10px 50px 10px;
    }
    .pu_parking_wrap{
        float: none;
        margin-top: 30px;
        width: 100%;
    }
    .pu_parking .pu_form{
        margin-top: 0;
    }
    .pu_parking_slid_wrap {
        display: block;
        float: none;
        width: 100%;
        margin-top: 30px;
    }
    .pu_parking.popup_main {
        max-width: 300px;
        padding: 60px 10px 50px 10px;
    }
    .pu_btn{
        margin-top: 22px;
    }
    .pu_area:after {
        width: 60px;
        height: 77px;
        background-size: 100%;
        top: 50%;
        left: 224px;
    }
    .pu_plan_wrap{
        width: 100%;
    }
    .pu_plan .pu_form{
        margin-top: 30px;
    }
    .form_tit{
        font-size: 18px;
        text-align: center;
    }
    .react_input_style + .react_input_style {
        margin-top: 14px;
    }
    .pu_plan_slid_wrap{
        width: 100%;
        margin-top: 40px;
    }
    .pu_floor.popup_main {
        max-width: 300px;
        padding: 60px 10px 50px 10px;
    }
    .pu_plan.popup_main {
        max-width: 300px;
        padding: 60px 10px 50px 10px;
    }
    .pu_tm span{
        font-size: 26px;
    }
    .select_floor {
        position: relative;
        margin-top: 40px;
        width: 100%;
        float: none;
    }
    .select_floor img{
        width: 100%;
    }
    .sel_fl_el{
        width: 117px;
    }
    .floor_img {
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
    }
    
    .footer .cw_main{
        flex-direction: column;
        max-width: 1400px;
    }
    .fot_l a{
        display: block;
        margin-bottom: 12px;
        text-align: center;
    }
    .fot_r{
        display: block;
        text-align: center;
        margin-top: 12px;
    }
    .fot_r img{
        margin: 0 auto;

    }
    .contacts #map{
        display: block;
        width: 100%;
        position: relative;
        height: 300px;
    }
    .contacts #map:before{
        display: none;
    }
    .contacts .cw_main{
        padding-top: 50px;
    }
    .cont_adress{
        margin-top: 60px;
    }
    .hod_str{
        padding-top: 80px;
    }
    .hd_slid{
        margin-top: 20px;
    }
    .hod_wrap {
        padding: 0 10px;
        max-width: 730px;
        margin: 0 auto;
        margin-top: 36px;
        flex-direction: column;
    }
    .liter_nav {
        display: block;
        width: auto;
        margin: 0 auto;
        max-width: 340px;
    }
    .hod_slid_wrap {
        width: 100%;
        margin-top: 30px;
    }
    .hod_nav {
        flex-direction: column;
    }
    .yrs_list {
        margin: 0 auto;
    }
    .ln_imgs{
        width: 300px;
    }
    .fin_bg{
        width: 126px;
    }
    .month_list{
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .hd_in img {
        max-width: 100%;
        height: auto;
        width: 100%;
        margin-right: 0;
    }
    .hod_nav_el{
        text-align: center;
    }
    .about_us .cw_main{
        padding-top: 0;
        padding-bottom: 0;
    }
    .doc_btn {
        width: 100%;
        height: 190px;
    }
    .doc_bg{
        width: 100%;
        height: 200px;
        position: relative;
    }
    .ab_left_bg, .ab_right_bg{
        display: none;
    }
    .ab_l, .ab_r{
        display: block;
        width: 100%;
    }
    .ab_l{
        background: #fb923b;
        padding: 20px;
    }
    .ab_r {
        padding: 20px;
    }
    .ab_text{
        font-size: 20px;
        margin-top: 30px;
    }
    .doc_wrap {
        display: block;
        width: 100%;
        position: relative;
        left: 0;
        bottom: 0;
        margin-top: 20px;
    }
    .kellers_img {
        display: block;
        position: relative;
        top: auto;
        right: auto;
        max-width: 100%;
        margin-top: 20px;
    }
    .kellers_text{
        margin-top: 20px;
        width: 100%;
        max-width: 100%;
    }
    .kellers_text{
        font-size: 18px;
    }
    .kellers_text span+span{
        margin-top: 16px;
    }
    .kellers a{
        margin-top: 30px;
    }
    .kellers{
        padding-bottom: 40px;
    }
    .prk_wrap{
        display: block;
        width: 100%;
        max-width: 100%;
    }
    .parking_img{
        display: block;
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        margin-top: 30px;
        width: 100%;
    }
    .park_info {
        padding-left: 44px;
        font-size: 20px;
        margin-top: 26px;
        background-size: 40px;
        background-position: center left;
    }
    .prk_list span{
        padding-left: 44px;
        font-size: 18px;
    }
    .prk_list span br{
        display: none;
    }
    .hb_el span{
        font-size: 18px;
    }
    .hb_el {
        width: 100%;
        padding: 80px 38px 24px 22px;
    }
    .hb_el a{
        left: 24px;
    }
    .hb_img {
        left: auto;
        width: 100%;
        position: relative;
        display: block;
        top: auto;
        margin-top: 20px;
    }
    .hb_info{
        margin-left: 0;
        font-size: 18px;
    }
    .how_buy{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .vac_info{
        margin-top: 20px;
    }
    .va_param{
        flex-direction: column;
    }
    .va_floor_slid{
        width: 100%;
        margin-top: 40px;
        position: relative;
        z-index: 4;
    }
    .va_floor_slid .va_txt{
        margin: 0 auto;
    }
    .va_floor_slid .slid_style_react.MuiSlider-root{
        width: 100%;
    }
    .slid_style_react.MuiSlider-root{
        margin-top: 40px;
    }
    .slid_style_react .MuiSlider-valueLabel {
        font-size: 15px;
        top: -14px;
    }
    .vacant_apart .form_main{
        position: relative;
        z-index: 4;
        flex-direction: column;
    }
    .vacant_apart .react_input_style  {
        margin-bottom: 10px;
    }
    .vacant_apart .form_main .react_input_style{
        width: 100%;
    }
    .plan_info {
        font-size: 18px;
        width: 100%;
        padding-left: 46px;
        background-size: 36px;
    }
    .plpr_in {
        width: auto!important;
        max-width: 300px;
    }
    .plan_price {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .plan_info {
        display: block;
        margin-left: 0;
        font-size: 20px;
        width: 100%;
    }
    .plpr_nav a{
        font-size: 20px;
    }
    .plpr_nav a+a{
        margin-left: 12px;
    }
    .plpr_nav{
        text-align: center;
    }
    .tm span br{
        display: none;
    }
    .repair_apart{
        padding-top: 60px;
    }
    .ra_wrap{
        margin-top: 40px;
    }
    .ra_wrap .cw_main{
        padding-top: 52px;
    }
    .ra_left{
        width: 100%;
    }
    .ra_img{
        display: block;
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        width: 100%;
        bottom: auto;
        margin-top: 15px;
    }
    .tm{
        font-size: 32px;
    }
    .advantage{
        padding-bottom: 60px;
        padding-top: 50px;
    }
    .advant_info_in title{
        font-size: 28px;
    }
    .advant_info_in span{
        margin-top: 10px;
        font-size: 18px;
    }
    .adv_l{
        padding-top: 20px;
        width: 100%!important;
        max-width: 100%;
    }
    .advant_slid_img{
        position: relative!important;
        top: auto;
        right: auto;
        width: 100%;
        margin-top: 30px;
    }
    .adv_info{
        margin: 0 auto;
        margin-top: 20px;
    }
    .infra{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .infra_inn{
        width: 100%;
        max-width: 100%;
    }
    .infra_info{
        padding: 0;
    }
    .infra_advant {
        align-items: center;
        flex-wrap: wrap;
        max-width: 200px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .inf_el{
        margin-bottom: 12px;
    }
    .infra_img {
        display: block;
        position: relative;
        right: auto;
        width: 100%;
        top: auto;
        margin-top: 30px;
    }
    /* #paths_plan{
        display: none;
    }
    .svg_wrap .genplan_main_img{
        display: none;
    }
    .wind_rose{
        display: none;
    }
    .lit_ident{
        position: relative;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        margin: 0 auto;
        margin-bottom: 14px;
    }
    .lit_ident {
        width: 280px;
        box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    }
    .genplan{
        padding-bottom: 40px;
    }
    .lit_info{
        width: 224px;
    } */
    .loc_img{
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
    }
    .wind_rose {
        width: 50px;
        height: 50px;
        left: 2%;
        bottom: 2%;
    }
    .genplan .cw_main {
        width: auto;
        top: auto;
        position: relative;
        transform: none;
        margin-bottom: 20px;
        left: auto;
        padding-top: 80px;
        max-width: 320px;
    }
    .genplan .cw_main .genp_info span {
        color: #1f2225;
        background-size: 44px;
        padding-left: 45px;
        font-size: 18px;
        margin-top: 30px;
    }
    .genplan .cw_main .tm{
        color: #1f2225;
    }
    .genplan .cw_main .genp_info:before{
        display: none;
    }
    .lit_ident{
        width: 130px;
    }
    .lit_num {
        height: 40px;
        font-size: 18px;
        width: 25%;
    }
    .lit_info {
        height: 40px;
        width: 75%;
        padding-left: 4px;
        padding-right: 2px;
    }
    .lit_info sup{
        font-size: 10px;
    }
    .lit_info span {
        font-size: 14px;
    }
    .lit_1 {
        left: 55%;
    }
    .lit_2 {
        left: 36%;
    }
    .lit_3 {
        left: 45%;
    }
    .lit_4 {
        left: 63%;
    }
    .ap_right{
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .ap_img{
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        max-width: 100%;
    }
    .about_project {
        padding-top: 80px;
        padding-bottom: 40px;
    }
    .presentation .cw_main{
        padding-top: 60px;
        padding-bottom: 100px;
    }
    .cw_main{
        max-width: 730px;
    }
    .menu .cw_main{
        max-width: 1400px;
    }
    .presentation_img{
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        max-width: 100%;
        z-index: 4;
        margin-top: 30px;
    }
    .presentation .form_main{
        flex-wrap: wrap;
        margin-top: 40px;
    }
    .presentation .form_main .react_input_style{
        width: 100%;
        margin-bottom: 12px;
    }
    .form_main .btn_main{
        width: 100%;
    }
    .presentation .form_main .react_input_style +.react_input_style{
        margin-bottom: 12px;
    }
    .galery .cw_main {
        top: auto;
        position: relative;
        transform: none;
        margin: 0 auto;
        left: auto;
    }
    .galery .tm {
        color: #1f2225;
        margin-bottom: 50px;
    }
    .galery{
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .gl_nav_wrap.cw_main{
        margin-top: 30px;
        text-align: center;
        display: block;
    }
    .gl_nav {
        width: 100%;
        padding-left: 0;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
    logo{
        max-width: 130px;
    }
    logo img{
        width: 100%;
    }
    .menu_btn{
        width: 50px;
        height: 50px;
    }
    .menu_btn{
        font-size: 14px;
    }
    .call_phone{
        font-size: 10px;
        margin-right: 12px;
        line-height: 28px;
    }
    .call_btn {
        width: 110px;
        height: 28px;
        font-size: 12px;
    }
    .pu_military_ipot{
        max-width: 574px;
    }
    .pu_military_ipot > img{
        display: none;
    }
    .pu_wrap{
        width: 100%;
    }
    .pu_military_ipot .pu_tm .dec2 {
        bottom: 3px;
        right: 79px;
    }

}
@media screen and (max-width: 960px) {
    .header_2 {
        padding-bottom: 120px;
    }
    .about_us > .dec2 {
        display: none;
    }
    .demo_l {
        padding-right: 0;
    }
    .demo_inner {
        display: block;
    }
    .demo_l, .demo_r {
        width: 100%;
    }
    .demo_list > li {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .demo_btn {
        margin-top: 20px;
    }
    .demo_r {
        margin-top: 40px;
    }
    .parking_action__title {
        font-size: 32px;
    }
    .parking_action .cw_main {
        padding-bottom: 60px;
        background-position: left top;
    }
}
@media screen and (max-width: 850px){
    .pu_action__btn img {
        width: 40px;
    }
    .pu_action__btn:before {
        top: 12px;
    }
    .pu_action {
        width: 380px;
    }
    .pu_form_action {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pu_form_action .pu_action__title {
        font-size: 24px;
    }
    .pu_form__in {
        display: block;
        margin-left: 0;
    }

    .in_block {
        width: 100%;
        margin-left: 0;
    }

        .in_block + .in_block {
            margin-top: 20px;
        }
        .pu_form__in {
            margin-left: 0;
            padding-left: 0;
        }
        .pu_form__in .react_input_style {
            width: 100%;
            padding-left: 0;
        }

        .pu_form__in .react_input_style + .react_input_style {
            margin-top: 10px;
        }
}
@media screen and (max-width: 760px) {
    .demo {
        padding-top: 62px;
    }
    .demo_l {
        padding-top: 0;
    }
}
@media screen and (max-height:  780px) {
    .winner {
        left: 60px;
        max-width: 100px;
    }
}
@media screen and (max-height:  760px) {
    .demo_inner {
        margin-top: 20px;
    }
    .pu_btn {
        margin-top: 20px;
    }
    .pu_form_action {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .pu_action__img {
        width: 100%;
    }
    .pu_action {
        width: 600px;
    }
}
@media screen and (max-height:  720px) {
    .pu_al__info2 {
        line-height: 100%;
    }
    .parking_action .cw_main:before {
        opacity: 0.4;
    }
    .parking_action__title {
        font-size: 28px;
    }
    .parking_action__title span {
        text-align: left;
        padding-right: 0;
    }
}
@media screen and (max-height:  600px) {
    .pu_action {
        width: 500px;
    }
    .pu_action__img {
        width: 100%;
    }
    .pu_action__title {
        font-size: 20px;
    }
    .pu_form_action {
        padding: 20px;
    }
    .pu_form__in .react_input_style {
        padding-left: 20px;
    }
    .pu_form__in {
        margin-left: -20px;
    }
}
@media screen and (max-height: 768px) and (max-width: 850px){
}
@media screen and (max-width: 780px) {
}
@media screen and (max-width: 768px){
    .pu_action__btn {
        bottom: 100px;
    }
}

@media screen and (max-width: 760px){
    .pu_action_main {
        padding: 66px 20px 36px 20px !important;
        margin-left: 15px;
        margin-right: 15px;
    }
    .pu_action_list > li {
        padding-left: 15px;
    }
    .pu_action_list {
        margin-left: -15px;
    }
}
@media screen and (max-width: 740px) {
    .header_title {
        font-size: 5.5vw;
    }
    .header_title_dop {
        font-size: 10vw;
    }
    .am__list  {
        margin-top: 40px;
    }
    .am__list {
        display: block;
        margin-left: 0;
    }
    .am__list > li {
        padding-left: 0;
        width: 100%;
    }
    .am__list > li + li {
        margin-top: 70px;
    }
    .action_mounth, .demo {
        padding-left: 0;
        padding-right: 0;
    }
    .quest_wrap{
        display: block;
        width: 100%;
        text-align: center;
    }
    .quest_wrap a{
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }
    .cont_adress{
        max-width: 100%;
        text-align: center;
    }
    .loc .cw_main{
    padding-top: 40px;
    padding-bottom: 0px;
    }
    .dec1, .dec2, .dec3, .dec4, .dec5, .dec6, .dec7, .dec8, .dec9, .dec10{
        display: none;
    }
    .loc_info{
        font-size: 16px;
        background-size: 25px;
    }
    .loc_text {
        margin-top: 24px;
    }
    .loc_text span+span{
        margin-top: 12px;
    }
    .loc_text{
        font-size: 16px;
    }
}
@media screen and (max-width: 660px) {
    .call_phone {
        display: block;
        text-align: center;
        margin: 0;
    }
}
@media screen and (max-width: 650px){
    .pu_al__name {
        font-size: 22px;
    }
    .popup_menu .closeform{
        right: 10px;
    }
    .menu_list a + a {
        margin-top: 10px;
    }
    .ab_list li{
        width: 50%;
    }
    .menu_list a{
        font-size: 20px;
    }
    
    .popup_main{
        max-width: 94%;
        padding: 70px 20px 40px 20px;
    }
    .pu_tm span{
        font-size: 24px;
    }
    .react_input_style + .react_input_style {
        margin-top: 9px;
    }
    .pu_form{
        margin-top: 40px;
    }
}
@media screen and (max-width:  590px) {
    .menu_btn {
        height: 40px;
    }
    .nemu_action {
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 5px;
        padding-bottom: 8px;
        margin-left: 10px;
    }
    .demo .tm span + span {
        font-size: 18px;
    }
    .menu_center {
        padding-left: 10px;
        padding-right: 10px;
        justify-content: center;
    }
    .nemu_action br {
        display: none;
    }
    .pu_tm2 {
        font-size: 28px;
    }  
    .pu_action_list, .pu_action_list2 {
        display: block;
        margin-top: 25px;
        margin-left: 0;
    }
    .pu_action_list > li, .pu_action_list2 > li {
        padding-left: 0;
        width: 100%;
    }
    .pu_action_list > li + li, .pu_action_list2 > li + li {
        margin-top: 15px;
    }
    .pu_action_main {
        max-width: 300px !important;
        margin: 0 auto;
    }
    .plpr_nav a {
        font-size: 18px;
    }
    .ra_nav{
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
        justify-content: center;
    }
    .ra_el+.ra_el{
        margin: 0 auto;
        margin-top: 20px;
    }
    .ra_btn {
        width: 95%;
        height: 86px;
        bottom: -43px;
        padding-left: 14px;
        font-size: 18px;
    }
    .tm{
        display: block;
        font-size: 28px;
        text-align: center;
    } 
    .tm sub{
        font-size: 18px;
    }
    .advant_nav{
        text-align: center;
    }
    .ap_info_img{
        position: relative;
        top: auto;
        right: auto;
        margin: 0 auto;
        margin-top: 12px;
    }
    .ap_right > p{
        padding-top: 20px;
        padding-right: 0;
    }
    .ap_list li{
        width: 50%;
    }
    .ap_right > p{
        font-size: 20px;
        text-align: justify;
    }
    
} 
@media screen and (max-width: 560px){
    .header_2 {
        padding-top: 76px;
        padding-bottom: 40px;
    }
    .header_2 .hd_inner {
        padding-bottom: 150px;
    }
    .winner {
        left: auto;
        right: 20px;
        max-width: 70px;
    }
    .military_btn {
        left: 50%;
        transform: translate(-50%, 120%);
    }
    /* .lit_num{
        font-size: 18px;
    }
    .lit_ident {
        width: 24px;
        height: 24px;
    }
    .lit_num{
        height: 100%;
    } */
    .lit_ident {
        transform: scale(.6) translateX(-50%);
    }
    .lit_4 {
        left: 66%;
        bottom: 55%;
    }
    .cont_adress p{
        font-size: 18px;
    }
    .cont_num{
        font-size: 26px;
        margin-top: 26px;
    }
    .open_time{
        font-size: 14px;
    }
    .quest_wrap span{
        font-size: 22px;
    }
    .quest_wrap a{
        margin-top: 12px;
    }
    logo {
        max-width: 90px;
    }
    .menu_btn {
        width: 40px;
        height: 40px;
    }
    .menu_left {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .call_phone{
        margin: 0 auto;
    }
    h1 {
        font-size: 28px;
    }
    .hd_inner{
        padding-top: 130px;
    }
    .header_plan {
        margin-bottom: 65px;
    }
    .header_plan {
        width: 230px;
    }
    .dom_rf_hd {
        right: 50%;
        transform: translateX(50%);
    }
    .gl_nav {
        height: auto;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 540px) {
    .pu_action {
        width: 390px;
    }
    .pu_action .pu_action__btn {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 500px) {
    .winner {
        max-width: 60px;
        right: 50%;
        margin-right: -30px;
        bottom: 100px;
    }
}

@media screen and (max-width: 480px){ 
    .menu {
        padding-top:5px;
        padding-bottom:5px;
    }
    .parking_action__title {
        font-size: 22px;
    }
    .parking_action__list > li {
        font-size: 24px;
        background-position: left 5px;
        padding-bottom: 2px;
    }
    .parking_action__content .btn_main {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 22px;
        padding-top: 8px;
        padding-bottom: 13px;
    }
    .parking_action__r {
        width: auto;
    }
    .parking_action .cw_main {
        padding-left: 40px;
        padding-right: 20px;
    }
    .vac_info{
        font-size: 20px;
    }
    .ap_list li{
        /* width: 50%; */
    }
    .ap_list li p{
        font-size: 14px;
    }
    .ab_list li{
        /* width: 100%; */
        text-align: center;
    }
    .ab_list li p{
        font-size: 12px;
    }
}

@media screen and (max-width: 460px) {
    .header_2 {
        padding-bottom: 100px;
    }
    .header_2 .winner {
        bottom: 70px;
    }
    .btn_head {
        width: 100%;
    }
    .head_img2 img {
        display: none;
    }
    .head_img2 img + img {
        display: block;
    }
    .nemu_action + .nemu_action {
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
    }

    .nemu_action {
        height: 30px;
        padding-top: 0;
        padding-bottom: 3px;
        line-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
    .menu_center {
        flex-wrap: wrap;
    }
    .menu_btn {
        height: 30px;
    }
    .hod_slid_wrap {
        margin-top: 0;
    }
    .hod_nav {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pu_action {
        width: 300px;
        border-width: 5px;
    }

    .pu_form_action .pu_action__title {
        font-size: 20px;
    }
    .pu_form_action {
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .pu_action__btn img {
        width: 30px;
    }
    .pu_action__btn:before {
        top: 8px;
    }

    .in_block + .in_block {
        margin-top: 15px;
    }

    .pu_form_action .align_center .pu_btn {
        margin-top: 15px;
    }
}

@media screen and (max-width:  420px) {
    .btn_head {
        font-size: 22px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 15px;
        margin-top: 30px;
        padding-bottom: 13px;
    }
    .header_2 {
        background-position: 60% bottom;
    }
    .header_title_dop {
        margin-top: 30px;
    }
    .about_project {
        padding-top: 10px;
    }
    .presentation .cw_main {
        padding-bottom: 50px;
    }
    .hod_str {
        padding-top: 40px;
    }
    .demo {
        padding-bottom: 40px;
    }
    .genplan .cw_main {
        padding-top: 0;
    }
    .action_mounth {
        padding-bottom: 74px;
    }
    .aml__title {
        font-size: 18px;
        line-height: 120%;
        height: 90px;
    }

    .aml__btn {
        height: 50px;
        bottom: -25px;
        font-size: 20px;
        padding-bottom: 5px;
    }
    .action_mounth {
        padding-top: 60px;
    }
    .am__list > li + li {
        margin-top: 50px;
    }
}

@media screen and (max-width:  380px) {
    .react_input_style .MuiInputLabel-root, .MuiInputBase-input {
        font-size: 20px !important;
    }
}

@media screen and (max-width:  360px) {
    .nemu_action + .nemu_action {
        font-size: 12px;
    }
    .call_btn {
        width: 96px;
    }
    .menu_center {
        padding-left: 5px;
        padding-right: 5px;
    }
    .call_phone {
        white-space: nowrap;
    }
    .demo_list > li {
        font-size: 18px;
        line-height: 20px;
        padding-top: 3px;
        padding-bottom: 6px;
    }
    .demo_list > li + li {
        margin-top: 10px;
    }
    .demo_btn {
        font-size: 20px;
    }
    .demo_slider_nav {
        margin-top: 16px;
    }
    .pu_tm span {
        font-size: 22px;
    }
}

@media screen and (max-width:  340px) {
    .pu_action {
        width: 260px;
    }
    .pu_form_action .pu_action__title {
        font-style: 18px;
    }
}

/*@media*/